import * as React from 'react';
import { useState, useEffect } from 'react';
import ShowOldRatio from './forms/ShowOldRatio';
import ShowOldRatioList from './forms/ShowOldRatioList';
import { bdAxios } from '../components/utils/Axios';
import { getToken, parseJwt } from '../components/utils/Common';
import Select from 'react-select'
import { useNavigate } from "react-router-dom";

function ReadRatios(data: any) {

    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState<any>('');
    const [selectedOffice, setSelectedOffice] = useState<any>();
    const [selectedRatio, setSelectedRatio] = useState<any>();
    const [offices, setOffices] = useState<any>();
    const [showList, setShowList] = useState<boolean>(false);
    const [showOld, setShowOld] = useState<boolean>(false);
    const [ratioList, setRatioList] = useState<any>();
    const [oldRatio, setOldRatio] = useState<any>();
    const [oldFdata, setOldFdata] = useState<any>();
    const [humanKeys, setHumanKeys] = useState<any>();
    const [years, setYears] = useState<any>();
    const [cumulYears, setCumulYears] = useState<any>();

    const types: any = [
        { key: "all", name: "Cumulatif 1 an pour l'ensemble des études" },
        { key: "one_year", name: "Cumulatif de 1 an pour une étude spécifique" },
        { key: "three_years", name: "Cumulatif de 3 ans pour une étude spécifique" },
        { key: "five_years", name: "Cumulatif de 5 ans pour une étude spécifique" },
    ];

    const nbNotaries: any = [
        { key: "all", name: "Tous les notaires" },
        { key: "1-4", name: "1 à 4 notaires" },
        { key: "5-8", name: "de 5 à 8 notaires" },
        { key: "9", name: "9 et plus" },
    ];

    const dataTypes: any = [
        { key: "general", name: "Données générales", isChecked: true },
        { key: "honoraries", name: "Répartition des honoraires", isChecked: true },
        { key: "rentability", name: "Ratios de rentabilité et de liquidité", isChecked: true },
        { key: "tarification", name: "Ratios de tarification", isChecked: true },
        { key: "salaries", name: "Ratios des recevables et des salaires", isChecked: true },
        { key: "expenses", name: "Ratios des dépenses", isChecked: true },
        { key: "production", name: "Ratios de production", isChecked: true },
        { key: "salary_stats", name: "Statistiques salariales", isChecked: true },
        { key: "my_office", name: "Rapport de la saisie de données de votre étude", isChecked: false },
    ];


    const getOfficeOptions = () => {
        if (!offices) {
            return [];
        }
        const options: Array<any> = [{ label: "Sélectionner", value: null }];
        offices.map((office: any) => {
            options.push({ value: office.ID, label: office.post_title })
        });

        return options;
    }

    useEffect(() => {
        let token = (getToken() ? getToken() : '');
        if (token === null) {
            return;
        }

        const user = parseJwt(token);

        const currentYear = new Date().getFullYear();
        let yrs = [];
        let year = currentYear - 10;
        let index = 0;
        while (year <= currentYear) {
            if (user.data.roles.indexOf("administrator") === -1 && user.data.roles.indexOf("pme_manager") === -1) {
                if (parseInt(data.configs.ratios[index]?.year) === year) {
                    if (data.configs.ratios[index].opened === false) {
                        index++;
                        year++;
                        continue;
                    }
                }
            }

            yrs.push({ key: year, name: year });
            year++;
            index++;
        }
        yrs.reverse();
        setYears(yrs);

        let cmlyrs: any = [];
        let cmlyear = currentYear - 10;
        index = 0;
        while (cmlyear <= currentYear) {
            if (user.data.roles.indexOf("administrator") === -1 && user.data.roles.indexOf("pme_manager") === -1) {
                if (parseInt(data.configs.ratios[index]?.year) === cmlyear) {
                    if (data.configs.ratios[index].cumul_access === false) {
                        index++;
                        year++;
                        continue;
                    }
                }
            }
            cmlyrs.push({ key: cmlyear, name: cmlyear });
            index++;
            cmlyear++;
        }
        cmlyrs.reverse();
        setCumulYears(cmlyrs);

        bdAxios.get('myoffices', {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then((r) => {
            const off: Array<any> = [];
            r.data.offices.map((office: any) => {
                if (office.post_parent === 0) {
                    off.push(office);
                }
            })

            off.sort((a: any, b: any) => {
                return a.post_title.localeCompare(b.post_title);
            });

            setOffices(off);
        })
    }, []);


    const submit = () => {
        let token = (getToken() ? getToken() : '');
        if (token === null) {
            return;
        }

        const form: HTMLFormElement | null = document.querySelector('#filter-form');
        if (!form) {
            return false;
        }

        const fd: any = new FormData(form);
        bdAxios.post('ratios/show', fd, {
            headers: {
                'Authorization': 'Bearer' + token
            }
        }).then((response) => {
            if (response.data.data.ratios) {
                //if (response.data.data.ratios.length > 1) {
                if (response.data.data.ratios.length > 0) {
                    setShowList(true);
                    setHumanKeys(response.data.data.labels)
                    setRatioList(response.data.data.ratios);
                    setOldFdata(response.data.data.fdata);

                } else {
                    if (response.data.data.ratios[0].hasOwnProperty('ID')) {
                        navigate('/ratio-form/' + response.data.data.ratios[0].ID)
                    } else {
                        setShowOld(true);
                        setOldRatio(response.data.data.ratios[0]);
                        setHumanKeys(response.data.data.labels)
                    }
                }
            } else {
                alert('Aucun ratio trouvé pour cette recherche');
            }
        }).catch((error) => {
            alert('Aucun ratio trouvé pour cette recherche');
        })
    }

    const showOldRatio = (ratio: any) => {
        setShowOld(true);
        setShowList(false);
        setOldRatio(ratio);
    }

    return (
        <div className="inner-page">
            {
                (!showList && !showOld) ?
                    <>
                        <h2>Consultations de ratios</h2>
                        <div>
                            <form id="filter-form">
                                <fieldset>
                                    <label>Type de cumulatif</label>
                                    <select name="type" onChange={e => setSelectedType(e.target.value)}>
                                        <option key={'cumul_n'}>Choisir un type</option>
                                        {types ?
                                            types.map((type: any) => {
                                                return (
                                                    <option key={'cumul_' + type.key} value={type.key}>{type.name}</option>
                                                )
                                            }) : void (0)}
                                    </select>

                                    {(selectedType !== '' && selectedType === "all") ?
                                        <>
                                            <label>Nombre de notaires</label>
                                            <select name="nb_notaries" onChange={e => setSelectedOffice(e.target.value)}>
                                                <option value="" key={'nbno_n'} >Choisir un nombre de notaire</option>
                                                {nbNotaries ?
                                                    nbNotaries.map((type: any) => {
                                                        return (
                                                            <option key={'nbno_t_' + type.key} value={type.key}>{type.name}</option>
                                                        )
                                                    }) : void (0)}
                                            </select>
                                        </>
                                        : void (0)
                                    }
                                    {
                                        (selectedType && selectedType !== "all" && selectedType !== "") ?
                                            <div className="ratio-office-select-wrapper">
                                                <label>Étude</label>
                                                <Select
                                                    options={getOfficeOptions()}
                                                    name="office"
                                                    className="office-select"
                                                />
                                            </div>
                                            : void (0)
                                    }


                                    <label>Données pour la période financière se terminant en</label>
                                    <select name="year">
                                        <option value="" key='year_n'>Année</option>
                                        {selectedType && selectedType !== "all" ?
                                            years ?
                                                years.map((type: any) => {
                                                    return (
                                                        <option key={'year_' + type.key} value={type.key}>{type.name}</option>
                                                    )
                                                }) : void (0)
                                            :
                                            cumulYears ?
                                                cumulYears.map((type: any) => {
                                                    return (
                                                        <option key={'year_c_' + type.key} value={type.key}>{type.name}</option>
                                                    )
                                                })
                                                : void (0)
                                        }
                                    </select>
                                    <div className="data-choices">
                                        <label>Données à afficher</label>
                                        <div className="options">
                                            {
                                                dataTypes.map((type: any, index: number) => {
                                                    return (
                                                        <label key={type.key}>{type.name}
                                                            <input key={'donnn_' + index} value={type.key} type="checkbox" name="dtype[]" defaultChecked={type.isChecked} />
                                                        </label>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="btnrow">
                                        <input onClick={submit} type="button" defaultValue="Consulter" className="back" />
                                    </div>
                                </fieldset>


                            </form>
                        </div>
                    </>
                    : void (0)
            }

            {showList ?
                <>
                    <ShowOldRatioList ratios={ratioList} humanKeys={humanKeys} fdata={oldFdata} />
                </>
                : void (0)
            }

            {oldRatio ?
                <ShowOldRatio selectedRatio={oldRatio} humanKeys={humanKeys} />
                : void (0)
            }
        </div>
    );
}

export default ReadRatios;