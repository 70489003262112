import * as React from 'react';
import { useState, useEffect, useRef, useReducer } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';

const ShowOldRatioList = (data: any) => {
    const [ratioLength, setRatioLength] = useState<number>(0);
    const [oldFormData, setOldFormData] = useState<any>();
    const tableRef = useRef<HTMLTableElement>(null);

    const exportName = 'Ratios';
    const exportFileName = 'export';

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    useEffect(() => {
        if (data.ratios) {
            setRatioLength(data.ratios.length);
        }

        if (data.fdata) {
            setOldFormData(data.fdata);
        }

        forceUpdate();

    }, [data]);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: exportFileName,
        sheet: exportName
    })


    let currency = new Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' })
    let percent = new Intl.NumberFormat('fr-CA', { 'maximumSignificantDigits': 3, 'style': 'percent' })
    let decimal = new Intl.NumberFormat('fr-CA', { 'maximumSignificantDigits': 3, 'style': 'decimal' })

    return (
        <>

            <div className="old-ratio old-ratio-list">

                <button onClick={onDownload}>Exporter</button>

                <table ref={tableRef} >
                    <thead>
                        <tr>
                            <td></td>
                            {
                                data.ratios.map((ratio: any) => {
                                    return <th className="value-only" data-key={Math.random()} > {ratio.colonne}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.humanKeys ?
                                Object.keys(data.humanKeys).map((key: any, index: any) => {

                                    // check if row is empty
                                    let canSkip = true;

                                    (ratioLength > 0) ?
                                        data.ratios.map((ratio: any) => {
                                            if (ratio[key]) {
                                                canSkip = false
                                            }
                                        })
                                        : void (0)

                                    if ('title' === data.humanKeys[key].type) {
                                        canSkip = false
                                    }

                                    if (['nom_utilisateur', 'complete'].includes(key)) {
                                        canSkip = true
                                    }

                                    if (canSkip) {
                                        return '';
                                    }

                                    if ('title' === data.humanKeys[key].type) {

                                        return (
                                            <tr>
                                                <th className="key-name" data-key={key} title={data.humanKeys[key].description} colSpan={ratioLength + 1}>{data.humanKeys[key].label}</th>
                                            </tr>
                                        )

                                    } else {

                                        return (
                                            <tr>
                                                <td className="key-name" data-key={key} title={data.humanKeys[key].description} >{data.humanKeys[key].label}</td>

                                                {
                                                    data.ratios.map((ratio: any) => {

                                                        let val = ratio[key]
                                                        let strVal = ''

                                                        switch (data.humanKeys[key].type) {
                                                            case 'currency':
                                                                val = parseFloat(val)
                                                                strVal = currency.format(val)
                                                                break;
                                                            case 'percent':
                                                                val = parseFloat(val)
                                                                strVal = percent.format(val)
                                                                break;
                                                            case 'number':
                                                                val = parseFloat(val)
                                                                strVal = decimal.format(val)
                                                                break;
                                                            case 'year':
                                                                strVal = val
                                                                break
                                                            case 'string':
                                                                strVal = val
                                                                if (strVal.length > 70) {
                                                                    strVal = strVal.substr(0, 70) + '…'
                                                                }
                                                                break
                                                        }

                                                        return (
                                                            <td title={ratio[key]} key={Math.random()} className="value-only">
                                                                {strVal}
                                                            </td>
                                                        )
                                                    }
                                                    )
                                                }
                                            </tr>
                                        )
                                    }
                                }
                                )
                                : void (0)
                        }
                    </tbody>
                </table>

            </div >
        </>
    )
}

export default ShowOldRatioList;